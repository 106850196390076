exports.Locale = {
    sunday: 'Di',
    monday: 'Lu',
    tuesday: 'Ma',
    wednesday: 'Me',
    thursday: 'Je',
    friday: 'Ve',
    saturday: 'Sa',
    ok: 'OK',
    today: "Aujourd'hui",
    yesterday: 'Hier',
    hours: 'Heures',
    minutes: 'Minutes',
    seconds: 'Secondes',
    last7Days: 'Les 7 derniers jours'
};