import React from 'react';
import { DatePicker, DateRangePicker } from 'rsuite';
import Chart from 'react-apexcharts';

import { Locale } from '../../const';
import { web, options, optionsLine } from '../../utils/charts';

import { getWebRequest } from '../../actions/webRequest';

class WebRequest extends React.Component {
    constructor() {
        super();
        this.state = {
            getAllWebRequest: [],
            getWebRequestStatus: false,
            getWebRequest: undefined,
            series: [{
                data: [],
                name: 'Nombre de requêtes'
            }],
            optionsLine: optionsLine,
            isMount: true
        }
        this._getRequestDate = this._getRequestDate.bind(this);
        this._getRequestWeek = this._getRequestWeek.bind(this);
    }

    async componentDidMount() {
        let temp = await getWebRequest();
        if (temp.status === 200) {
            this.state.optionsLine.chart.selection.xaxis.min = new Date(temp.data[0].x).setHours(0, 0, 0, 0).valueOf() + 12000000
            this.state.optionsLine.chart.selection.xaxis.max = new Date(temp.data[temp.data.length - 1].x).setHours(0, 0, 0, 0).valueOf()
            this.setState({
                getAllWebRequest: temp.data, getWebRequestStatus: true, series: [{
                    data: temp.data,
                    name: 'Nombre de requêtes'
                }],
                optionsLine: this.state.optionsLine
            })
        }
        this._getRequestDate(new Date());
    }

    componentWillUnmount() {
        this.setState({
            isMount: false
        })
    }

    async _getRequestDate(e) {
        let temp = await getWebRequest(e.toDateString());
        if (temp.status === 200 && this.state.isMount)
            return this.setState({ getWebRequest: `${e.toLocaleDateString()} il y eu ${temp.data.number} vues` })
        !this.state.isMount && this.setState({ getWebRequest: false })
    }

    async _getRequestWeek(e) {
        if (e.length === 0)
            return;
        this.setState({
            getWebRequestStatus: false
        })
        let temp = await getWebRequest(e[0].toDateString(), e[1].toDateString());
        if (temp.status === 200 && this.state.isMount) {
            this.state.optionsLine.chart.selection.xaxis.min = new Date(temp.data[0].x).setHours(0, 0, 0, 0).valueOf() + 12000000
            this.state.optionsLine.chart.selection.xaxis.max = new Date(temp.data[Math.round(temp.data.length / 1.5)].x).setHours(0, 0, 0, 0).valueOf()
            options.dataLabels.enabled = true
            if (temp.data.length > 100)
                options.dataLabels.enabled = false
            this.setState({
                series: [{
                    data: temp.data,
                    name: 'Nombre de requêtes',
                }],
                getWebRequestStatus: true,
                optionsLine: this.state.optionsLine
            })
        }
    }

    render() {
        return (
            <main>
                <h1 className="mb-3">Requête web</h1>
                <DatePicker style={{ width: 280, marginRight: "2rem" }} oneTap showWeekNumbers locale={Locale} onChange={this._getRequestDate} />
                <DateRangePicker locale={Locale} onChange={this._getRequestWeek} />

                <div className="my-4">
                    {this.state.getWebRequest ? (
                        <h3>Pour la date du {this.state.getWebRequest}</h3>
                    ) : <h3>Aucune vue pour cette date </h3>}
                </div>

                {this.state.getWebRequestStatus && (
                    <div className="material-card date-chart my-4">
                        <Chart options={web} series={this.state.series} type="area" />
                    </div>
                )}

                {!this.state.getWebRequestStatus &&
                    <div className="position-fixed p-center">
                        <div className="spinner-border" role="status" />
                    </div>
                }

                {this.state.getWebRequestStatus && (
                    <div className="material-card date-chart my-4">
                        <div id="wrapper">
                            <div id="chart-line2">
                                <Chart options={options} series={this.state.series} type="bar" height={230} />
                            </div>
                            <div id="chart-line">
                                <Chart options={this.state.optionsLine} series={this.state.series} type="area" height={130} />
                            </div>
                        </div>
                    </div>
                )}
            </main>
        )
    }
}

export default WebRequest;