import axios from 'axios';
import config from '../config';
import { disconnect } from '../actions/connection';
import { isConnected } from '../actions/connection'

async function httpRequest(url, method, params, retry = 0, e) {
    if (retry === 5)
        if (e.response)
            return e.response.data;
        else
            return { code: 'error', message: 'Serveur distant non opérationnel' };
    try {
        const { data, status } = await axios({
            baseURL: config.apiUrl,
            url,
            method,
            data: params,
            headers: {
                'Authorization': isConnected,
            }
        });
        return { data, status };
    } catch (e) {
        if (e.response.status === 401)
            return disconnect();
        return httpRequest(url, method, params, retry + 1, e);
    }
}

export default httpRequest;