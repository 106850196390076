import httpRequest from '../utils/httpRequest';

export function getAllAppointments() {
    return httpRequest('/appointments', 'get');
}

export function createAppointments(params) {
    return httpRequest('/appointment/create?sendMail=false', 'post', params);
}

export function getUndefinedAppointments() {
    return httpRequest('/appointments?type=undefined', 'get');
}

export function getAppointments(id) {
    return httpRequest(`/appointment/${id}`, 'get');
}

export function setappointmentsdate(id, params) {
    return httpRequest(`/appointment/${id}/setdate`, 'put', params)
}

export function deleteappointment(id) {
    return httpRequest(`/appointment/${id}`, 'delete');
}