import React from 'react';

import { contactmodification } from '../../actions/contact';

import { ReactComponent as CloseIcon } from '../../assets/icons/x-circle.svg';

class Contact extends React.Component {
    constructor() {
        super();
        this.state = {
            showClientModification: false
        }
        this.showChange = this.showChange.bind(this);
        this.changeContactModification = this.changeContactModification.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.showChange);
    }

    showChange(e) {
        if (e.target.parentNode.id === "client") {
            this.clientModification = { name: e.target.title, selected: e.target.ariaRoleDescription, value: e.target.ariaValueText }
            return this.setState({ showClientModification: !this.state.showClientModification });
        }
    }

    async changeContactModification(e) {
        e.preventDefault();
        if ((await contactmodification({ email: this.props.contactInfo.email, selected: this.clientModification.selected, value: this.clientModification.value })).status === 200) {
            this.setState({ showClientModification: false });
            this.props.contactRequest(this.clientModification.selected, this.clientModification.value);
        }
    }
    render() {
        return (
            <div>
                <div className="material-card client-informations mb-4" id="client">
                    <p title="Email" aria-valuetext={this.props.contactInfo.email} aria-roledescription="email">Adresse mail : {this.props.contactInfo.email}</p>
                    <p title="Nom" aria-valuetext={this.props.contactInfo.nom} aria-roledescription="nom">Nom : {this.props.contactInfo.nom}</p>
                    <p title="Prénom" aria-valuetext={this.props.contactInfo.prenom} aria-roledescription="prenom">Prénom : {this.props.contactInfo.prenom}</p>
                    <p title="Numéro" aria-valuetext={0 + this.props.contactInfo.cell_number} aria-roledescription="cell_number">Numéro de téléphone : 0{this.props.contactInfo.cell_number}</p>
                    <p title="Adresse" aria-valuetext={this.props.contactInfo.adress} aria-roledescription="adress">Adresse : {this.props.contactInfo.adress}</p>
                    <p title="Code postal" aria-valuetext={this.props.contactInfo.zip_code} aria-roledescription="zip_code">Code postal : {this.props.contactInfo.zip_code}</p>
                    <p title="Ville" aria-valuetext={this.props.contactInfo.city} aria-roledescription="city">Ville : {this.props.contactInfo.city}</p>
                </div>

                {this.state.showClientModification && (
                    <div className="material-card set-card p-center client">
                        <CloseIcon onClick={() => this.setState({ showClientModification: false })} className="close-icon" />
                        <h3>Client</h3>
                        <form onSubmit={this.changeContactModification}>
                            <span className="d-flex align-items-center py-4">
                                <p>{this.clientModification.name} :</p>
                                <input type="text" className="form-control" defaultValue={this.clientModification.value} onChange={(e) => this.clientModification.value = e.target.value} />
                            </span>
                            <button className="btn btn-success">Valider</button>
                        </form>
                    </div>
                )}
            </div>
        )
    }
}

export default Contact;