import React from 'react';
import { Link } from 'react-router-dom';

import { getallcontacts } from '../../actions/contact';

import { ReactComponent as EditIcon } from '../../assets/icons/edit-2.svg';

class Contacts extends React.Component {
    constructor() {
        super();
        this.state = {
            getallcontacts: false,
            getallcontactsStatus: false
        }
    }

    async componentDidMount() {
        let temp = await getallcontacts();
        if (temp.status === 200)
            this.setState({ getallcontacts: temp.data.reverse(), getallcontactsStatus: true })
    }

    render() {
        return (
            <main>
                <h1 className="mb-3">Contacts</h1>
                <div className="contact-container">
                    {this.state.getallcontactsStatus && (
                        this.state.getallcontacts.length === 0 ? <h3>Aucun contacts</h3> :
                            this.state.getallcontacts.map(data => (
                                <Link to={{
                                    pathname: '/contacts/information',
                                    state: {
                                        contact: data
                                    }
                                }} className="material-card contact-card" key={data.email}>
                                    <div className="d-flex justify-content-between">
                                        <h4>{data.email}</h4>
                                        <EditIcon />
                                    </div>
                                    <p>{data.nom}</p>
                                    <p>{data.prenom}</p>
                                    <p>0{data.cell_number}</p>
                                    <p>{data.adress}</p>
                                    <p>{data.zip_code}</p>
                                    <p>{data.city}</p>
                                </Link>
                            ))
                    )}

                    {!this.state.getallcontactsStatus && (
                        <div className="position-fixed p-center">
                            <div className="spinner-border" role="status" />
                        </div>
                    )}

                </div>
            </main >
        )
    }
}

export default Contacts;