import React from 'react';

import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg'

class Nav extends React.Component {
    render() {
        return (
            <header>
                <MenuIcon className="menu-icon" onClick={this.props.changeLeftNav} />
                <h4>Administration</h4>
                <a href="https://jardinfo.fr">Jardinfo.fr</a>
            </header>
        )
    }
}

export default Nav;