import React from 'react';

import Appointment from '../appointment-card/Appointment';
import UndefinedAppointment from '../appointment-card/UndefinedAppointment';
import Contact from '../contact-card/Contact';

import { getcontact, getcontactappointments, deleteContact } from '../../actions/contact';

import { ReactComponent as AlertIcon } from '../../assets/icons/alert-circle.svg';

class Contacts extends React.Component {
    constructor(props) {
        super();
        this.state = {
            showClientModification: false,
            contactAppointments: false,
            contactAppointmentsStatus: false
        }
        if (props.location.state)
            this.state.contactInfo = props.location.state.contact;
        this.appointmentsRequest = this.appointmentsRequest.bind(this);
        this.contactRequest = this.contactRequest.bind(this);
        this.deleteContactRequest = this.deleteContactRequest.bind(this);
    }

    componentDidMount() {
        this.appointmentsRequest();
    }

    async appointmentsRequest() {
        if (this.state.contactInfo) {
            let temp = await getcontactappointments({ email: this.state.contactInfo.email });
            if (temp.status === 200)
                this.setState({ contactAppointments: temp.data, contactAppointmentsStatus: true });
        }
    }

    async contactRequest(selected, value) {
        if (selected === 'email') {
            this.props.location.state.contact.email = value;
            this.props.history.replace('/contacts/information', this.props.location.state);
        }
        else {
            let temp = await getcontact({ email: this.state.contactInfo.email });
            if (temp.status === 200)
                this.setState({ contactInfo: temp.data[0] });
        }
    }

    async deleteContactRequest() {
        let temp = await deleteContact({ email: this.state.contactInfo.email });
        if (temp.status === 200)
            this.props.history.replace('/contacts');
    }

    render() {
        return (
            <main>
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className="mb-3">Contacts</h1>
                    {this.state.contactAppointments.length === 0 && <button onClick={() => this.setState({ deleteConfirmation: true })} className="btn set-date-button">Supprimer ce contact</button>}
                </div>
                {this.props.location.state ? (
                    <Contact contactInfo={this.state.contactInfo} contactRequest={this.contactRequest} />
                ) : <h3 className="position-fixed p-center">Ce contact n'a pas été trouvé</h3>}

                {this.state.contactAppointmentsStatus && (
                    this.state.contactAppointments.length === 0 ? <h3>Aucun rendez-vous</h3> :
                        this.state.contactAppointments.map(data => (
                            data.date ?
                                <Appointment appointmentInfo={data} key={data.id} />
                                : <UndefinedAppointment appointmentInfo={data} key={data.id} appointmentsRequest={this.appointmentsRequest} />
                        )))}

                {!this.state.contactAppointmentsStatus && (
                    <div className="position-fixed p-center" style={{ top: 'auto', bottom: '20%' }}>
                        <div className="spinner-border" role="status" />
                    </div>
                )}

                {this.state.deleteConfirmation && (
                    <div className="toast position-fixed p-center delete-confirmation" role="alert" style={{ opacity: 1, zIndex: 100 }}>
                        <div className="toast-header">
                            <AlertIcon />
                            <strong className="ml-2 mr-auto">Suppresion d'un contact</strong>
                            <button type="button" className="ml-2 mb-1 close" onClick={() => this.setState({ deleteConfirmation: false })}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="toast-body d-flex flex-column">
                            <p>Êtes-vous sur de supprimer ce contact ?</p>
                            <button type="button" className="btn btn-danger mt-2" onClick={this.deleteContactRequest}>Supprimer</button>
                        </div>
                    </div>
                )}
            </main >
        )
    }
}

export default Contacts;