import React from 'react';

import { connect, connectSuccess } from '../../actions/connection';

class Connection extends React.Component {
    constructor() {
        super();
        this.state = {
            error: 'd-none',
            usernameError: 'd-none',
            passwordError: 'd-none'
        }
        this.insertValue = this.insertValue.bind(this);
        this.connection = this.connection.bind(this);
    }

    insertValue(e) {
        this[e.target.name] = e.target.value;
    }

    async connection(e) {
        e.preventDefault();
        this.setState({ error: 'd-none', usernameError: 'd-none', passwordError: 'd-none' });
        let temp = await connect({ username: this.username, password: this.password });
        if (temp.status === 200)
            return connectSuccess(temp.data.token);
        this.state[temp.code] = '';
        this.setState(this.state);
    }

    render() {
        return (
            <form className="connection p-center" onSubmit={this.connection}>
                <h1 className="mb-3 text-center">Connexion</h1>
                <small className={`text-danger text-center ${this.state["error"]}`}>Requête invalide</small>
                <div className="my-3">
                    <input type="username" placeholder="Nom d'utilisateur" className="form-control" name="username" onChange={this.insertValue} required />
                    <small className={`text-danger ${this.state["usernameError"]}`}>Utilisateur incorrect</small>
                </div>
                <div className="my-3">
                    <input type="password" placeholder="Mot de passe" className="form-control" name="password" onChange={this.insertValue} required />
                    <small className={`text-danger ${this.state["passwordError"]}`}>Mot de passe incorrect</small>
                </div>
                <input type="submit" className="btn btn-primary mt-3" value="Se connecter" />
            </form>
        )
    }
}

export default Connection;