import httpRequest from '../utils/httpRequest';

export function getServerInformations() {
    return httpRequest('/server/pm2', 'get');
}

export function pm2Action(action, name) {
    return httpRequest(`/server/pm2?action=${action}&name=${name}`, 'post');
}

export function getMachineInformations() {
    return httpRequest('/server/machine', 'get');
}

export function getMetrics(date = 0, type) {
    if (type)
        return httpRequest(`/server/metrics?type=${type}&date=${date}`, 'get');
    return httpRequest(`/server/metrics?date=${date}`, 'get');
}

export function serverAction(actions) {
    return httpRequest(`/server/action?action=${actions}`, 'post');
}