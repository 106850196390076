import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Dropdown } from 'rsuite';

import { cpu, disk, network } from '../../utils/charts';

import { getServerInformations, getMachineInformations, getMetrics, serverAction, pm2Action } from '../../actions/server';

import { ReactComponent as PowerIcon } from '../../assets/icons/off.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh.svg';
import { ReactComponent as PoweringIcon } from '../../assets/icons/power.svg';
import { ReactComponent as RefreshingIcon } from '../../assets/icons/refresh-cw.svg';

class Server extends React.Component {
    constructor() {
        super();
        this.state = {
            serverInformationsRequest: false,
            machineInformationsRequest: false,
            metricsDate: { name: '1 semaine', date: 604800 },
            metricsRequest: false,
            isMount: true
        }
        this.cpu_series = [{
            data: [],
            name: "Utilisation"
        }];
        this.disk_series = [{
            data: [],
            name: 'Ecriture'
        },
        {
            data: [],
            name: "Lecture"
        }];
        this.network_series = [{
            data: [],
            name: "Upload"
        },
        {
            data: [],
            name: "Download"
        }];
        this.pm2Informations = this.pm2Informations.bind(this);
        this.machineInformations = this.machineInformations.bind(this);
        this.getMetricsInformations = this.getMetricsInformations.bind(this);
    }

    componentDidMount() {
        //this.machineInformations();
        //this.pm2Informations();
        //this.getMetricsInformations();
    }

    componentWillUnmount() {
        this.setState({
            isMount: false
        })
        window.clearInterval(this.interval);
    }

    async pm2Informations() {
        let temp = await getServerInformations();
        if (temp.status === 200 && this.state.isMount)
            this.setState({
                serverInformations: temp.data, serverInformationsRequest: true
            });
    }

    async pm2Actions(e, action, name) {
        e.preventDefault();
        pm2Action(action, name);
        setTimeout(this.pm2Informations, 200);
    }

    async machineInformations() {
        let temp = await getMachineInformations();
        if (temp.status === 200 && this.state.isMount)
            this.setState({
                machineInformationsRequest: true, machineInformations: temp.data
            });
    }

    async getMetricsInformations(e) {
        let temp;
        if (e) {
            this.setState({ metricsRequest: false, metricsDate: e });
            temp = await getMetrics(1000 * e.date);
        } else
            temp = await getMetrics(1000 * this.state.metricsDate.date);
        if (temp.status === 200) {
            this.cpu_series[0].data = temp.data.cpu.values;
            this.disk_series[0].data = temp.data.disk["disk.0.bandwidth.write"].values
            this.disk_series[1].data = temp.data.disk["disk.0.bandwidth.read"].values
            this.network_series[0].data = temp.data.network["network.0.bandwidth.out"].values;
            this.network_series[1].data = temp.data.network["network.0.bandwidth.in"].values;
            this.setState({ metricsRequest: true })
        }
    }

    render() {
        return (
            <main>
                <h1 className="mb-3">Serveur</h1>

                <h3 className="mb-3">Le monitoring du serveur est pour l'instant indisponible</h3>

                {this.state.machineInformationsRequest && (
                    <div className="material-card server-information align-items-center mb-5">
                        <h3 className="mb-3">Informations machine</h3>
                        <p className="mb-3">Hostname : {this.state.machineInformations.hostname}</p>
                        <p className="mb-3">Temps de fonctionnement : {this.state.machineInformations.uptime > 120 ? Math.round(this.state.machineInformations.uptime / 60) + ' h' : this.state.machineInformations.uptime + ' m'}</p>
                        <p className="mb-3">Processeur :  {this.state.machineInformations.loadavg} %</p>
                        <p className="mb-3">Total mémoire : {this.state.machineInformations.totalmem} mo</p>
                        <p className="mb-3">Mémoire libre: {this.state.machineInformations.freemem} mo</p>
                        <p className="mb-3">Mémoire utilisé: {this.state.machineInformations.usemem} mo</p>
                    </div>
                )}

                {this.state.serverInformationsRequest && (
                    <div className="pm2-informations mb-5">
                        {this.state.serverInformations.map((data, index) => (
                            <div key={index} className="material-card server-information">
                                <div className="d-flex justify-content-between mb-3">
                                    <h3 className="mr-3">{data.name}</h3>
                                    <PoweringIcon className={`action-button ${data.pm2_env.status === 'online' && 'poweron'}`} onClick={(e) => { if (data.pm2_env.status === 'online') return this.pm2Actions(e, 'stop', data.name); this.pm2Actions(e, 'start', data.name) }} />
                                    <RefreshingIcon className="action-button restart" onClick={(e) => this.pm2Actions(e, 'restart', data.name)} />
                                </div>
                                <p className="mb-3">Processeur : {data.monit.cpu} %</p>
                                <p className="mb-3">Memoire : {Math.round(data.monit.memory / 1000000)} mo</p>
                                <p className="mb-3">Crée le : {new Date(data.pm2_env.pm_uptime).toLocaleString()}</p>
                                <p>Temps de fonctionnement : {Math.round((new Date() - new Date(data.pm2_env.pm_uptime)) / 1000 / 60) > 120 ? Math.round((new Date() - new Date(data.pm2_env.pm_uptime)) / 1000 / 3600) + ' h' : Math.round((new Date() - new Date(data.pm2_env.pm_uptime)) / 1000 / 60) + ' m'}</p>
                            </div>
                        ))}
                    </div>
                )}

                {this.state.metricsRequest && (
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="server">
                                <PowerIcon className="action-button mr-3" onClick={(e) => { e.preventDefault(); serverAction('shutdown') }} />
                                <RefreshIcon className="action-button" onClick={(e) => { e.preventDefault(); serverAction('reboot') }} />
                            </div>
                            <Dropdown activeKey={this.state.metricsDate} title={this.state.metricsDate.name} appearance="default" onSelect={this.getMetricsInformations} style={{ color: 'black' }}>
                                <Dropdown.Item eventKey={{ name: '1 heure', date: 3600 }}>1 heure</Dropdown.Item>
                                <Dropdown.Item eventKey={{ name: '1 jour', date: 86400 }}>1 jour</Dropdown.Item>
                                <Dropdown.Item eventKey={{ name: '1 semaine', date: 604800 }}>1 semaine</Dropdown.Item>
                                <Dropdown.Item eventKey={{ name: '1 mois', date: 18144000 }}>1 mois</Dropdown.Item>
                            </Dropdown>
                        </div>
                        <ReactApexChart options={cpu} series={this.cpu_series} type="area" height={200} />
                        <ReactApexChart options={disk} series={this.disk_series} type="area" height={200} />
                        <ReactApexChart options={network} series={this.network_series} type="area" height={200} />
                    </div>
                )}

                {(!this.state.metricsRequest || !this.state.machineInformationsRequest || !this.state.serverInformationsRequest) &&
                    <div className="position-fixed p-center">
                        <div className="spinner-border" role="status" />
                    </div>
                }

            </main>
        )
    }
}

export default Server;