import httpRequest from '../utils/httpRequest';

export function connect(params) {
    return httpRequest('/login', 'post', params);
}

export function disconnect() {
    localStorage.removeItem('PrivateTokenApi');
    localStorage.setItem('currentPage', window.location.pathname);
    return window.location.replace('/');
}

export function connectSuccess(token) {
    localStorage.setItem('PrivateTokenApi', token);
    let currentPage = localStorage.getItem('currentPage') || '/informations';
    return window.location.replace(currentPage);
}

export var isConnected = localStorage.getItem('PrivateTokenApi') || '';