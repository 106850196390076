import React from 'react';

import UndefinedAppointment from '../appointment-card/UndefinedAppointment';

import { getUndefinedAppointments } from '../../actions/appointments';

class Informations extends React.Component {
    constructor() {
        super();
        this.state = {
            allAppointments: false,
            allAppointmentsStatus: false
        }
        this.undefinedAppointmentRequest = this.undefinedAppointmentRequest.bind(this);
    }

    componentDidMount() {
        this.undefinedAppointmentRequest();
    }

    async undefinedAppointmentRequest() {
        let temp = await getUndefinedAppointments();
        if (temp.status === 200)
            this.setState({ allAppointments: temp.data, allAppointmentsStatus: true });
    }

    render() {
        return (
            <main>
                <h1>Renseignements</h1>
                {this.state.allAppointmentsStatus && (
                    this.state.allAppointments.length === 0 ? <h3>Aucun rendez-vous</h3> :
                        this.state.allAppointments.map(data => (
                            <UndefinedAppointment appointmentInfo={data} {...this.props} key={data.id} appointmentsRequest={this.undefinedAppointmentRequest} />
                        ))
                )}

                {!this.state.allAppointmentsStatus && (
                    <div className="position-fixed p-center">
                        <div className="spinner-border" role="status" />
                    </div>
                )}
            </main>
        )
    }
}

export default Informations;