import React from 'react';
import { DatePicker, TagPicker } from 'rsuite';

import { Locale } from '../../const';
import { getAllAppointments, createAppointments } from '../../actions/appointments';
import page_jardinage from '../../assets/json/page_jardinage.json';
import page_informatique from '../../assets/json/page_informatique.json';

import Appointment from '../appointment-card/Appointment';

import { ReactComponent as CloseIcon } from '../../assets/icons/x-circle.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/plus-circle.svg';

class AppointmentsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            allAppointments: false,
            allAppointmentsStatus: false,
            showSendRDV: false,
            showRs: false,
            showDate: false,
            mailExist: false
        }
        this.getAllAppointmentsRDV = this.getAllAppointmentsRDV.bind(this);
        this.insertValue = this.insertValue.bind(this);
        this.sendRDV = this.sendRDV.bind(this);
    }

    componentDidMount() {
        this.getAllAppointmentsRDV();
    }

    async getAllAppointmentsRDV() {
        let temp = await getAllAppointments();
        if (temp.status === 200)
            this.setState({ allAppointments: temp.data, allAppointmentsStatus: true });
    }

    insertValue(e) {
        this[e.target.name] = e.target.value;
    }

    async sendRDV(e) {
        e.preventDefault();
        if (this.time && this.date) {
            this.time.setSeconds(0, 0);
            this.time.setFullYear(this.date.getFullYear(), this.date.getMonth(), this.date.getDate())
        }
        const tmp = (await createAppointments({ email: this.email, name: this.name, last_name: this.last_name, cell_number: this.cell_number, adress: this.adress, zip_code: this.zip, city: this.city, type: this.type, prestation: this.props.prestation, message: this.message, date: this.time }))
        if (tmp.status === 200 && tmp.data.code !== 'warning')
            if (this.date) {
                this.setState({ showSendRDV: false });
                this.getAllAppointmentsRDV();
            }
            else
                this.props.history.push('/informations')
    }

    render() {
        let data = [];
        page_jardinage.prestation.map((value, index) => {
            return data[index] = { value: value.title, label: value.title };
        })
        page_informatique.prestation_description.map(({ title, liste }) => {
            return liste.map(({ description }) => {
                return data.push({ label: description, value: description, type: title });
            })
        });

        data[data.length] = { label: 'Autre...', value: 'Autre...', type: 'Autre' }
        return (
            <main>
                <div className="d-flex justify-content-between align-items-center">
                    <h1>Rendez-vous</h1>
                    <button onClick={() => this.setState({ showSendRDV: true })} className="btn set-date-button">Insérer un RDV</button>
                </div>
                {this.state.allAppointmentsStatus && (
                    this.state.allAppointments.length === 0 ? <h3>Aucun rendez-vous</h3> :
                        this.state.allAppointments.map(data => (
                            <Appointment appointmentInfo={data} key={data.id} />
                        ))
                )}

                {!this.state.allAppointmentsStatus && (
                    <div className="position-fixed p-center">
                        <div className="spinner-border" role="status" />
                    </div>
                )}

                {this.state.showSendRDV && (
                    <form className="material-card set-card rdv p-center" onSubmit={this.sendRDV}>
                        <CloseIcon onClick={() => this.setState({ showSendRDV: false })} className="close-icon" />
                        <h3>Rendez-vous</h3>

                        <div className="form-group">
                            <label>Adresse e-mail</label>
                            <input type="email" className="form-control" placeholder="nom@exemple.fr" name="email" onChange={this.insertValue} required />
                            <label className="m-0">Si le contact n'existe pas, vous pouvez cliquer <p className="emailExist" onClick={() => this.setState({ mailExist: !this.state.mailExist })}>ici</p></label>
                        </div>

                        {this.state.mailExist && (
                            <div>
                                <div className="form-panel">
                                    <span>
                                        <div>
                                            <label>Nom</label>
                                            <input type="text" className="form-control" name="name" onChange={this.insertValue} required />
                                        </div>

                                        <div>
                                            <label>Prénom</label>
                                            <input type="text" className="form-control" name="last_name" onChange={this.insertValue} required />
                                        </div>
                                    </span>

                                    <div>
                                        <label>Numéro de téléphone</label>
                                        <input type="tel" className="form-control" pattern="^0\d(\s|-)?(\d{2}(\s|-)?){4}$" name="cell_number" onChange={this.insertValue} required />
                                    </div>
                                </div>

                                <div className="form-panel">
                                    <div className="adress">
                                        <label>Adresse</label>
                                        <input type="text" className="form-control" name="adress" onChange={this.insertValue} required />
                                    </div>

                                    <span>
                                        <div>
                                            <label>Code postal</label>
                                            <input type="text" className="form-control" name="zip" id="zip" pattern="^(\d{5}(\s|-)?)$" onChange={this.insertValue} required />
                                        </div>

                                        <div>
                                            <label>Ville</label>
                                            <input type="text" className={"form-control " + this.state.city_error} name="city" id="city" onChange={this.insertValue} required />
                                        </div>
                                    </span>
                                </div>
                            </div>
                        )}

                        <TagPicker
                            className={`my-3 ${this.state.prestation_error}`}
                            data={data}
                            placeholder="Sélectionner votre prestation"
                            onChange={(sel) => { this.type = sel; this.setState({ prestation_error: '' }) }}
                            style={{ width: '100%' }}
                        />

                        <div className="d-flex mb-3">
                            <p className="mr-3">Ajouter une date</p>
                            <AddIcon onClick={() => this.setState({ showDate: !this.state.showDate })} style={{ cursor: 'pointer' }} />
                        </div>

                        {this.state.showDate && (
                            <div className="d-flex flex-column">
                                <span>Jour : <DatePicker oneTap style={{ width: 280 }} locale={Locale} onChange={(e) => this.date = e} /></span>
                                <span>Heure : <DatePicker format="HH:mm" style={{ width: 280 }} locale={Locale} onChange={(e) => this.time = e} ranges={[]} /></span>
                            </div>
                        )}

                        <div className="d-flex mb-3">
                            <p className="mr-3">Ajouter un renseignement</p>
                            <AddIcon onClick={() => this.setState({ showRs: !this.state.showRs })} style={{ cursor: 'pointer' }} />
                        </div>

                        {this.state.showRs && (
                            <div className="form-group w-100">
                                <label>Renseignements</label>
                                <textarea className="form-control" rows="5" name="message" onChange={this.insertValue} required />
                            </div>
                        )}

                        <button className="btn btn-success" type='submit'>Valider</button>
                    </form>
                )}
            </main>
        )
    }
}

export default AppointmentsPage;