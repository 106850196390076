import React from 'react';
import { Link } from 'react-router-dom';
import { DatePicker } from 'rsuite';

import { Locale } from '../../const';

import { setappointmentsdate, deleteappointment } from '../../actions/appointments';

import { ReactComponent as CloseIcon } from '../../assets/icons/x-circle.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/x.svg';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert-circle.svg';

class UndefinedAppointment extends React.Component {
    constructor() {
        super();
        this.state = {
            showSendDate: false,
            deleteConfirmation: false
        }
        this.sendDate = this.sendDate.bind(this);
        this.deleteAppointment = this.deleteAppointment.bind(this);
    }

    async sendDate(e) {
        e.preventDefault();
        if (this.time && this.date) {
            this.time.setSeconds(0, 0);
            if ((await setappointmentsdate(this.props.appointmentInfo.id, { date: this.time.setFullYear(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()) })).status === 200)
                if (this.props.match && this.props.match.path === '/informations')
                    this.props.history.push('/appointments');
                else
                    this.props.appointmentsRequest();
        }
    }

    async deleteAppointment() {
        let temp = await deleteappointment(this.props.appointmentInfo.id);
        if (temp.status === 200) {
            this.setState({ deleteConfirmation: false });
            this.props.appointmentsRequest();
        }
    }

    render() {
        return (
            <div>
                <div className="delete-card material-card my-4 py-0 pr-0">
                    <div key={this.props.appointmentInfo.id} className="undefinedAppointment py-2">
                        <Link to={`/informations/${this.props.appointmentInfo.id}`}>
                            <div className="d-flex align-items-center">
                                <p className="m-0">{this.props.appointmentInfo.email}</p>
                                {this.props.appointmentInfo.type.map((prest, index) => (
                                    index !== this.props.appointmentInfo.type.length - 1 ? (
                                        <p key={prest} className="type-item">{prest}</p>
                                    ) : <p key={prest}>{prest}</p>
                                ))}
                            </div>
                        </Link>
                        <button onClick={() => this.setState({ showSendDate: true })} className="btn set-date-button mr-3">Définir une date</button>
                    </div>
                    <DeleteIcon className="delete-icon" onClick={() => this.setState({ deleteConfirmation: true })} />
                </div>

                {this.state.showSendDate && (
                    <form className="material-card set-card date p-center" onSubmit={this.sendDate}>
                        <CloseIcon onClick={() => this.setState({ showSendDate: false })} className="close-icon" />
                        <h3>Veuillez selectionner une date</h3>
                        <span>Jour : <DatePicker oneTap style={{ width: 280 }} locale={Locale} onChange={(e) => this.date = e} /></span>
                        <span>Heure : <DatePicker format="HH:mm" style={{ width: 280 }} locale={Locale} onChange={(e) => this.time = e} ranges={[]} /></span>
                        <button className="btn btn-success mt-3" type='submit'>Valider</button>
                    </form>
                )}

                {this.state.deleteConfirmation && (
                    <div className="toast position-fixed p-center delete-confirmation" role="alert" style={{ opacity: 1, zIndex: 100 }}>
                        <div className="toast-header">
                            <AlertIcon />
                            <strong className="ml-2 mr-auto">Suppresion d'un RDV</strong>
                            <button type="button" className="ml-2 mb-1 close" onClick={() => this.setState({ deleteConfirmation: false })}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="toast-body d-flex flex-column">
                            <p>Êtes-vous sur de supprimer ce RDV ?</p>
                            <button type="button" className="btn btn-danger mt-2" onClick={this.deleteAppointment}>Supprimer</button>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default UndefinedAppointment;