import React from 'react';
import { Link } from 'react-router-dom';

class Appointment extends React.Component {
    render() {
        return (
            <Link to={`/appointments/${this.props.appointmentInfo.id}`} className="material-card my-4 appointments">
                <div>
                    <p className="mb-0">{this.props.appointmentInfo.email}</p>
                    {this.props.appointmentInfo.type.map((prest, index) => (
                        index !== this.props.appointmentInfo.type.length - 1 ? (
                            <p key={prest} className="type-item">{prest}</p>
                        ) : <p key={prest}>{prest}</p>
                    ))}
                </div>
                <p className="mb-0">{new Date(this.props.appointmentInfo.date).toLocaleString()}</p>
            </Link>
        )
    }
}

export default Appointment;