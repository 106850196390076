exports.cpu = {
    chart: {
        id: 'Cpu',
        type: 'area',
        height: 350,
        foreColor: '#fff',
        toolbar: {
            show: false
        },
        events: {
            mounted: function(ctx, config) {
                var lowest = ctx.getLowestValueInSeries(0)
                var highest = ctx.getHighestValueInSeries(0)

                ctx.addPointAnnotation({
                    x: new Date(ctx.w.globals.seriesX[0][ctx.w.globals.series[0].indexOf(lowest)]).getTime(),
                    y: lowest,
                    label: {
                        text: `${Math.round(lowest * 100) / 100} %`,
                        offsetY: 2,
                        style: {
                            color: '#212121'
                        }
                    }
                })

                ctx.addPointAnnotation({
                    x: new Date(ctx.w.globals.seriesX[0][ctx.w.globals.series[0].indexOf(highest)]).getTime(),
                    y: highest,
                    label: {
                        text: `${Math.round(highest * 100) / 100} %`,
                        offsetY: 2,
                        style: {
                            color: '#212121'
                        }
                    },
                })
            }
        }
    },
    title: {
        text: 'Cpu',
        margin: 35,
        align: 'center',
        style: {
            fontSize: '24px',
            fontWeight: 'bold'
        },
    },
    fill: {
        opacity: 0.9,
        type: 'gradient'
    },
    xaxis: {
        labels: {
            show: false,
            formatter: function(value, timestamp, index) {
                return new Date(value * 1000).toLocaleString();
            }
        }
    },
    yaxis: {
        labels: {
            formatter: function(value, timestamp, index) {
                return `${Math.round(value)} %`;
            }
        }
    },
    stroke: {
        curve: 'straight'
    },
    dataLabels: {
        enabled: false
    },
    tooltip: {
        theme: 'dark',
        y: {
            formatter: function(value, timestamp, index) {
                return `${Math.round(value * 100) / 100} %`;
            }
        },
    }
}

exports.disk = {
    chart: {
        id: 'Disk',
        type: 'area',
        height: 350,
        foreColor: '#fff',
        toolbar: {
            show: false
        },
        events: {
            mounted: function(ctx, config) {
                var lowest = ctx.getLowestValueInSeries(0);
                var highest = ctx.getHighestValueInSeries(0);

                ctx.addPointAnnotation({
                    x: new Date(ctx.w.globals.seriesX[0][ctx.w.globals.series[0].indexOf(lowest)]).getTime(),
                    y: lowest,
                    label: {
                        text: `${Math.round(lowest) / 1000} KBps`,
                        offsetY: 2,
                        style: {
                            color: '#212121'
                        }
                    }
                })

                ctx.addPointAnnotation({
                    x: new Date(ctx.w.globals.seriesX[0][ctx.w.globals.series[0].indexOf(highest)]).getTime(),
                    y: highest,
                    label: {
                        text: `${Math.round(highest) / 1000} KBps`,
                        offsetY: 2,
                        style: {
                            color: '#212121'
                        }
                    },
                })
            }
        }
    },
    title: {
        text: 'Disk',
        margin: 35,
        align: 'center',
        style: {
            fontSize: '24px',
            fontWeight: 'bold'
        },
    },
    fill: {
        opacity: 0.9,
        type: 'gradient'
    },
    xaxis: {
        labels: {
            show: false,
            formatter: function(value, timestamp, index) {
                return new Date(value * 1000).toLocaleString();
            }
        }
    },
    yaxis: {
        labels: {
            formatter: function(value, timestamp, index) {
                if (value > 2000)
                    return `${value / 1000} KBps`;
                return `${value} Bps`;
            }
        }
    },
    stroke: {
        curve: 'straight'
    },
    dataLabels: {
        enabled: false
    },
    tooltip: {
        theme: 'dark',
        y: {
            formatter: function(value, timestamp, index) {
                if (value >= 1000)
                    return `${Math.round(value) / 1000} KBps`;
                return `${Math.round(value)} Bps`;
            }
        },
    }
}

exports.network = {
    chart: {
        id: 'Network',
        type: 'area',
        height: 350,
        foreColor: '#fff',
        toolbar: {
            show: false
        },
        events: {
            mounted: function(ctx, config) {
                var lowest0 = ctx.getLowestValueInSeries(0);
                var highest0 = ctx.getHighestValueInSeries(0);
                var lowest1 = ctx.getLowestValueInSeries(1);
                var highest1 = ctx.getHighestValueInSeries(1);

                ctx.addPointAnnotation({
                    x: new Date(ctx.w.globals.seriesX[0][ctx.w.globals.series[0].indexOf(lowest0)]).getTime(),
                    y: lowest0,
                    label: {
                        text: `${Math.round(lowest0) / 1000} KBps`,
                        offsetY: 2,
                        style: {
                            color: '#212121'
                        }
                    }
                })

                ctx.addPointAnnotation({
                    x: new Date(ctx.w.globals.seriesX[0][ctx.w.globals.series[0].indexOf(highest0)]).getTime(),
                    y: highest0,
                    label: {
                        text: `${Math.round(highest0) / 1000} KBps`,
                        offsetY: 2,
                        style: {
                            color: '#212121'
                        }
                    },
                })

                ctx.addPointAnnotation({
                    x: new Date(ctx.w.globals.seriesX[1][ctx.w.globals.series[1].indexOf(lowest1)]).getTime(),
                    y: lowest1,
                    label: {
                        text: `${Math.round(lowest1) / 1000} KBps`,
                        offsetY: 2,
                        style: {
                            color: '#212121'
                        }
                    }
                })

                ctx.addPointAnnotation({
                    x: new Date(ctx.w.globals.seriesX[1][ctx.w.globals.series[1].indexOf(highest1)]).getTime(),
                    y: highest1,
                    label: {
                        text: `${Math.round(highest1) / 1000} KBps`,
                        offsetY: 2,
                        style: {
                            color: '#212121'
                        }
                    },
                })
            }
        }
    },
    title: {
        text: 'Network',
        margin: 35,
        align: 'center',
        style: {
            fontSize: '24px',
            fontWeight: 'bold'
        },
    },
    fill: {
        opacity: 0.9,
        type: 'gradient'
    },
    xaxis: {
        labels: {
            show: false,
            formatter: function(value, timestamp, index) {
                return new Date(value * 1000).toLocaleString();
            }
        }
    },
    yaxis: {
        labels: {
            formatter: function(value, timestamp, index) {
                if (value >= 1000)
                    return `${Math.round(value) / 1000} KBps`;
                return `${Math.round(value)} Bps`;
            }
        }
    },
    stroke: {
        curve: 'straight'
    },
    dataLabels: {
        enabled: false
    },
    tooltip: {
        theme: 'dark',
        y: {
            formatter: function(value, timestamp, index) {
                if (value >= 1000)
                    return `${Math.round(value) / 1000} KBps`;
                return `${Math.round(value)} Bps`;
            }
        },
    }
}

exports.web = {
    chart: {
        id: 'area-datetime',
        type: 'area',
        height: 350,
        zoom: {
            autoScaleYaxis: true
        },
        foreColor: '#fff',
        toolbar: {
            theme: 'dark',
        },
        events: {
            mounted: function(ctx, config) {
                var lowest = ctx.getLowestValueInSeries(0);
                var highest = ctx.getHighestValueInSeries(0);

                ctx.addPointAnnotation({
                    x: new Date(ctx.w.globals.seriesX[0][ctx.w.globals.series[0].indexOf(lowest)]).getTime(),
                    y: lowest,
                    label: {
                        text: 'Min :' + lowest,
                        offsetY: 2,
                        style: {
                            color: '#212121'
                        }
                    }
                })

                ctx.addPointAnnotation({
                    x: new Date(ctx.w.globals.seriesX[0][ctx.w.globals.series[0].indexOf(highest)]).getTime(),
                    y: highest,
                    label: {
                        text: 'Max : ' + highest,
                        offsetY: 2,
                        style: {
                            color: '#212121'
                        }
                    },
                })
            }
        }
    },
    dataLabels: {
        enabled: false
    },
    markers: {
        size: 0,
        style: 'hollow',
    },
    xaxis: {
        type: 'datetime',
        tickAmount: 6,
    },
    tooltip: {
        theme: 'dark',
        x: {
            format: 'dd MMM yyyy'
        }
    },
    fill: {
        type: 'gradient',
        opacity: 0.9,
        gradient: {
            gradientToColors: ['#EA52F8', '#0066FF']
        }
    }
}

exports.options = {
    chart: {
        id: 'chart2',
        type: 'area',
        height: 230,
        foreColor: '#fff',
        toolbar: {
            theme: 'dark',
        },
    },
    colors: ['#FFCA28'],
    stroke: {
        width: 3
    },
    dataLabels: {
        enabled: true
    },
    fill: {
        opacity: 1,
    },
    markers: {
        size: 0
    },
    xaxis: {
        type: 'datetime'
    },
    yaxis: {
        labels: {
            formatter: function(value, timestamp, index) {
                return Math.round(value);
            }
        }
    },
    tooltip: {
        theme: 'dark',
        x: {
            format: 'dd MMM yyyy'
        }
    }
}

exports.optionsLine = {
    chart: {
        id: 'chart1',
        height: 130,
        type: 'area',
        foreColor: '#fff',
        brush: {
            target: 'chart2',
            enabled: true
        },
        selection: {
            enabled: true,
            xaxis: {
                min: new Date(),
                max: new Date().setDate(new Date().getDate() - 7)
            },
            fill: {
                color: '#fff'
            },
            stroke: {
                color: '#FFC107'
            },
        },
    },
    colors: ['#008FFB'],
    fill: {
        type: 'gradient',
        gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
        }
    },
    xaxis: {
        type: 'datetime',
        tooltip: {
            enabled: false
        }
    },
    yaxis: {
        tickAmount: 2
    }
}