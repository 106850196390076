import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Notification } from 'rsuite';

import { isConnected, disconnect } from '../actions/connection'
import config from '../config';

import Connection from './Connection/Connection';
import Nav from './Navigation/Nav';
import LeftNav from './Navigation/LeftNav';

import InformationsPage from './Page/InformationsPage';
import AppointmentsPage from './Page/AppointmentsPage';
import DisplayAppointments from './Page/DisplayAppointment';
import Contacts from './Page/Contacts';
import ContactInformation from './Page/ContactInformation';
import Server from './Page/Server';
import WebRequest from './Page/WebRequest';

class App extends React.Component {
    constructor() {
        super();
        this.state = {
            leftNav: false,
            webSocketStatus: false
        }
        this.ws = null;
        this._initWebsocket = this._initWebsocket.bind(this);
        this.receivewebSocketMessage = this.receivewebSocketMessage.bind(this);
        this.changeLeftNav = this.changeLeftNav.bind(this);
    }

    componentDidMount() {
        this._initWebsocket();
    }

    _initWebsocket(nbClose = 0) {
        if (isConnected) {
            this.ws = new WebSocket(config.wsUrl)
            this.ws.onopen = () => {
                console.log('WebSocket connected');
                this.ws.send(isConnected);
                this.setState({
                    webSocketStatus: true
                })
            };

            this.ws.onmessage = (message) => {
                const webSocketMessage = JSON.parse(message.data);
                if (webSocketMessage.code)
                    disconnect()
                if (webSocketMessage.notificationsMessage) {
                    webSocketMessage.notificationsMessage.map((data) => {
                        return Notification.info({
                            title: data.title,
                            duration: 0,
                            description: (
                                <div className="mr-3">
                                    <p style={{ whiteSpace: 'pre-line', marginBottom: 0 }} >{data.message}</p>
                                </div>
                            )
                        });
                    })
                    if (webSocketMessage.notificationsMessage.length !== 0)
                        Notification.warning({
                            title: 'Supprimer tous',
                            duration: 0,
                            description: (
                                <button type="button" className="btn btn-warning" onClick={Notification.closeAll}>Valider</button>
                            )
                        });
                }
            }

            this.ws.onclose = () => {
                if (nbClose !== 6)
                    setTimeout(this._initWebsocket, 10000, nbClose + 1)
            }
        }
    }

    receivewebSocketMessage(data) {
        if (this.state.webSocketStatus)
            this.ws.onmessage = (message) => {
                data.setState({
                    webSocketMessage: JSON.parse(message.data)
                })
            }
    }

    changeLeftNav() {
        this.setState({ leftNav: !this.state.leftNav });
    }

    render() {
        return (
            <Router>
                <Route exact path='/' component={Connection} />
                {isConnected ? (
                    <div className='main-application'>
                        <Nav changeLeftNav={this.changeLeftNav} />
                        <div className="d-flex">
                            <LeftNav leftNav={this.state.leftNav} changeLeftNav={this.changeLeftNav} />
                            <Switch>
                                <Route path='/informations' exact component={InformationsPage} />
                                <Route path='/informations/:id' exact component={DisplayAppointments} />
                                <Route path='/appointments' exact component={AppointmentsPage} />
                                <Route path='/appointments/:id' exact component={DisplayAppointments} />
                                <Route path='/contacts' exact component={Contacts} />
                                <Route path='/contacts/information' exact component={ContactInformation} />
                                <Route path='/webrequest' exact component={WebRequest} />
                                <Route path='/server' exact component={Server} />
                                <Redirect to='/informations' />
                            </Switch>
                        </div>
                    </div>
                )
                    : <Redirect to="/" />
                }
            </Router>
        )
    }
}

export default App;