import React from 'react';

import Contact from '../contact-card/Contact';

import { getAppointments } from '../../actions/appointments';

class DisplayAppointment extends React.Component {
    constructor() {
        super();
        this.state = {
            allAppointmentsStatus: 'Getting',
        }
        this.getAppointmentsRDV = this.getAppointmentsRDV.bind(this);
    }

    componentDidMount() {
        this.getAppointmentsRDV();
        document.addEventListener('click', this.showChange);
    }

    async getAppointmentsRDV() {
        let temp = await getAppointments(this.props.match.params.id);
        if (temp.status === 200)
            this.setState({ allAppointmentsStatus: 'Success', allAppointments: temp.data });
        else
            this.setState({ allAppointmentsStatus: 'Error' });
    }

    render() {
        return (
            <main>
                <h1>Rendez-vous</h1>
                {this.state.allAppointmentsStatus === 'Getting' && (
                    <div className="position-fixed p-center">
                        <div className="spinner-border" role="status" />
                    </div>
                )}

                {this.state.allAppointmentsStatus === 'Success' && (
                    <div>
                        <Contact contactInfo={this.state.allAppointments} contactRequest={this.getAppointmentsRDV} />
                        <div className="material-card client-informations mb-4">
                            <h5>Renseignements :</h5>
                            <p className="ml-4">- {this.state.allAppointments.renseignements}</p>
                            <h5>Prestations :</h5>
                            <div className="ml-4">
                                {this.state.allAppointments.type.map((prest, index) => (
                                    <p key={prest} className="mr-2">- {prest}</p>
                                ))}
                            </div>
                            <p>Date : {this.state.allAppointments.date ? new Date(this.state.allAppointments.date).toLocaleString() : 'Non définit'}</p>
                        </div>
                    </div>
                )}

                {this.state.allAppointmentsStatus === 'Error' && (
                    <h3 className="position-fixed p-center">Ce RDV n'a pas été trouvé</h3>
                )}
            </main>
        )
    }
}

export default DisplayAppointment;