import httpRequest from '../utils/httpRequest';

export function getallcontacts() {
    return httpRequest('/contacts', 'get');
}

export function deleteContact(params) {
    return httpRequest('/contact', 'delete', params)
}

export function getcontactappointments(params) {
    return httpRequest('/contact/appointments', 'post', params);
}

export function getcontact(params) {
    return httpRequest('/contact', 'post', params);
}

export function contactmodification(params) {
    return httpRequest('/contact', 'put', params);
}