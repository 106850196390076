import React from 'react';
import { NavLink } from 'react-router-dom';

import { disconnect } from '../../actions/connection';

import { ReactComponent as InformationtIcon } from '../../assets/icons/information.svg';
import { ReactComponent as AppointmentIcon } from '../../assets/icons/appointment.svg';
import { ReactComponent as WebRequestIcon } from '../../assets/icons/it.svg';
import { ReactComponent as ContactIcon } from '../../assets/icons/phonebook.svg';
import { ReactComponent as ServerIcon } from '../../assets/icons/server.svg';
import { ReactComponent as DisconnectIcon } from '../../assets/icons/disconnect.svg';

class LeftNav extends React.Component {
    render() {
        return (
            <nav className={`left-navbar ${this.props.leftNav && 'left-navbar-transition'}`}>
                <NavLink to='/informations' className="nav-item" onClick={this.props.changeLeftNav}><InformationtIcon />Renseignements</NavLink>
                <NavLink to='/appointments' className="nav-item" onClick={this.props.changeLeftNav}><AppointmentIcon />Rendez-vous</NavLink>
                <NavLink to='/contacts' className="nav-item" onClick={this.props.changeLeftNav}><ContactIcon />Contacts</NavLink>
                <NavLink to='/webrequest' className="nav-item" onClick={this.props.changeLeftNav}><WebRequestIcon />Requêtes web</NavLink>
                <NavLink to='/server' className="nav-item" onClick={this.props.changeLeftNav}><ServerIcon />Serveur</NavLink>
                <button onClick={disconnect} className="disconnect-button"><DisconnectIcon />Deconnexion</button>
            </nav>
        )
    }
}

export default LeftNav;